.mainCont {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.stats {
    display: flex;
    align-self: center;
}

.statsMatchContainer {
    display: flex;
    flex-direction: row;
}

.statsRow {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

.matchListContainer {
    display: flex;
    flex-direction: column;
    align-items: center;


}

.profileStats {
    margin-right: 0vh;
    width: 90vh
}
